/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
@tailwind preflight;
/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
@tailwind components;
/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */
/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;
/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */
body.env-hero {
  --nav-hover-color: #00338c; }

body.env-grolsch {
  --nav-hover-color: #004b31; }

body.env-bacardi {
  --nav-hover-color: #c10020; }

body.report {
  padding: 10px !important;
  background: #eee !important; }
  body.report td {
    vertical-align: top; }
  body.report.fotos .blazy-photo {
    display: initial; }
    body.report.fotos .blazy-photo.loading {
      width: 160px;
      min-height: 160px;
      background: #1E1E1E url("../../public/img/loader.gif") center center no-repeat;
      display: inline-block; }
  body.report .input-control.select {
    width: auto; }
  body.report .layer .stat-counters {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 6px; }
    body.report .layer .stat-counters .stat-counter {
      margin: 3px 0.71%; }
      body.report .layer .stat-counters .stat-counter .stat-counter-inner {
        border: 1px solid #eaeaea;
        padding: 10px 12px;
        text-align: center;
        min-height: 86px; }
        body.report .layer .stat-counters .stat-counter .stat-counter-inner p {
          height: 30px;
          display: block;
          line-height: 16px;
          margin-top: 5px;
          font-size: 90%; }

body header {
  position: relative;
  z-index: 3; }
  body header ul.h-menu {
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); }
    body header ul.h-menu li:hover {
      background-color: var(--nav-hover-color);
      color: white; }
    body header ul.h-menu li.no-hover:hover, body header ul.h-menu li.no-hover:hover a {
      background-color: initial;
      color: initial; }

#sidenav li.active, #sidenav li.active > a, .h-menu li.active, .h-menu li.active > a {
  background-color: #453030;
  color: white; }

#sidenav li:hover, #sidenav li:hover > a, .h-menu li:hover, .h-menu li:hover > a {
  background-color: var(--nav-hover-color);
  color: white; }

.box {
  padding: 10px;
  background: white;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin: 0; }

.layer {
  width: 100%;
  margin-bottom: 10px; }

#content {
  height: calc(100vh - 56px); }

.table.striped tbody tr:nth-child(2n+1) {
  background: #f2f2f2; }

.dataTables_info {
  background: #f2f2f2; }

.table.row-border .tr + .tr, .table.row-border tr + tr {
  border-top: 1px #dfdfdf solid; }

.blazy-photo {
  display: none; }

body.report.loading::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: white; }

.photo {
  width: 160px;
  max-width: 100%;
  height: auto;
  vertical-align: top; }
  .photo.b-error {
    background: rgba(255, 0, 0, 0.2); }
    .photo.b-error:after {
      content: "404, not found"; }

.dataTables_wrapper {
  padding-bottom: 40px; }

.graph {
  width: 100%;
  float: left;
  height: 0;
  padding-bottom: 100%;
  position: relative; }
  .graph.graph-4-3 {
    padding-bottom: 75%; }
  .graph.graph-16-9 {
    padding-bottom: 56.25%; }
  .graph.graph-4-2 {
    padding-bottom: 50%; }
  .graph.graph-3-1 {
    padding-bottom: 33.3%; }
  .graph.graph-4-1 {
    padding-bottom: 25%; }
  .graph canvas {
    width: 100% !important;
    height: 100% !important;
    position: absolute; }

.graph-row {
  float: left;
  width: 100%; }

.graph-25 {
  float: left;
  width: 25%; }
  @media (max-width: 1750px) {
    .graph-25 {
      width: 50%; } }

.fusioncharts-container {
  width: 100% !important; }
